@media screen and (min-width: 800px) {
.myLogo {
  color: Black;

  margin-top: 20px;
  font-size: 24px;
  font-family: Times New Roman;
}
.navbar {
  height: 70px;
  border-bottom: thistle;
  background-color:whitesmoke;
  background-image: none;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  font-size: 17px;
}

.List > li {
  float: left;
}
a {
  color: black;
  font-weight: normal;
  text-shadow: none;
}
a:hover {
  color: whitesmoke;
  font-weight: normal;
  text-shadow: none;
  background-color: gray;
}
.navbar-center {
  position: absolute;
  left: 40%;
}
}
@media screen and (max-width: 600px) {
  .myLogo {
    color: Black;
    padding-top: 5px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 10px;
    font-family: cursive;
  }
  .navbar {
    height: 70px;
    border-bottom: thistle;
    background-color:whitesmoke ;
    background-image: none;
    padding-bottom: 20px;
    font-size: 10px;
  }
  
  .List > li {
    float: left;
  }
  a {
    color: black;
    font-weight: normal;
    text-shadow: none;
  }
  a:hover {
    color: whitesmoke;
    font-weight: normal;
    text-shadow: none;
    background-color: gray;
  }

  
}

.footer_wrap {
	border-top: 1px solid #d7d7d7;
	position: relative;

}
.go_top_btn1{

  position: fixed;
    right: 10px;
    bottom: 10px;
  color:black;
    padding: 5px 15px;
    font-size: 16px;

    z-index: 999;
    @media screen and (max-width: 600px) {
      margin-right: 0px;
      position: fixed;

  
    }
    
}